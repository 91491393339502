/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"
import { imageUrlFor } from "./blog/lib/image-url"

function SEO({
  description,
  lang,
  meta,
  title,
  noindex,
  baseUrl,
  singleLang,
  openGraphImage,
}) {
  const intl = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const image = openGraphImage
    ? `${imageUrlFor(openGraphImage).width(630)}`
    : "https://cdn.sanity.io/images/vrx9irrf/production/143ff9065b85d1805c1baba11b249838fa2d9ddc-2156x1230.png"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={
        !noindex && !singleLang
          ? [
              {
                rel: "canonical",
                href: `https://www.coderschool.vn/${intl.locale}/${baseUrl}`,
              },
              {
                rel: "alternate",
                hreflang: "x-default",
                href: `https://www.coderschool.vn/en/${baseUrl}`,
              },
              {
                rel: "alternate",
                hreflang: "en",
                href: `https://www.coderschool.vn/en/${baseUrl}`,
              },
              {
                rel: "alternate",
                hreflang: "vi",
                href: `https://www.coderschool.vn/vi/${baseUrl}`,
              },
            ]
          : singleLang && singleLang === intl.locale
          ? [
              {
                rel: "canonical",
                href: `https://www.coderschool.vn/${singleLang}/${baseUrl}`,
              },
              {
                rel: "alternate",
                href: `https://www.coderschool.vn/${singleLang}/${baseUrl}`,
                hreflang: "x-default",
              },
              {
                rel: "alternate",
                href: `https://www.coderschool.vn/${singleLang}/${baseUrl}`,
                hreflang: singleLang,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        { property: `og:image`, content: image },
        {
          property: `og:description`,
          content: metaDescription,
        },
        { name: "image", content: image },
        { name: "twitter:image", content: image },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {noindex || (singleLang && singleLang !== intl.locale) ? (
        <meta name="robots" content="noindex" />
      ) : null}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
