/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/themes/conceptual.css"
// import "./src/themes/rheostat.css"
import "./src/themes/charles.css"
import "./src/themes/new-course.css"
// import "./src/themes/khoa.css"
import "./src/themes/react-video-modal.css"
import "./src/themes/quiz.css"
import "./src/themes/blog.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
